import "./App.css";
import logo from './assets/Logo_Arosoft.png'
function App() {
  return (
    <>
      <div className="container">
        <img src={logo} alt="Logo de Arosoft"></img>
        <h1>PRÓXIMAMENTE</h1>
        <h2>
          <em>Arosoft.hn</em> está por llegar
        </h2>
      </div>
    </>
  );
}

export default App;
