import "./Footer.css";
import miniLogo from "./assets/favicon.ico";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-column">
        <div className="footer-logo-text">
          <img src={miniLogo} alt="Footer logo" />
          <p>&copy; {new Date().getFullYear()} Arosoft S. de R.L. de C.V. Todos los derechos reservados.</p>
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-icon-text">
          <svg fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em">
            <path d="M3 2a2 2 0 012-2h6a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V2zm6 11a1 1 0 10-2 0 1 1 0 002 0z" />
          </svg>
          <p>
            <b>Contáctenos</b><br />
            +504 9512-4111 | +504 2561-2601
          </p>
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-icon-text">
          <svg viewBox="0 0 16 16" fill="currentColor" height="1em" width="1em">
            <path fillRule="evenodd" d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
          <p>
            <b>Dirección</b><br />
            Barrio Los Andes, contiguo a Plaza Victoria, 8 y 9 calle, 10 avenida, San Pedro Sula, Cortés, Honduras
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
